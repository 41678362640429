import { NotFound } from 'components/Common';
import HomePage from 'features/home';
import TestExaminationData from 'features/test/TestExaminationData';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const RouteConfig = () => {
  const routes = [
    {
      path: '/testExaminationData',
      component: TestExaminationData
    },
    {
      path: '/*',
      component: HomePage
    },
    {
      path: '',
      component: NotFound
    }
  ];
  // const navigate = useNavigate();
  // const location = useLocation();

  // const { authStore } = useStore();
  // will handle rote with authentication
  // useEffect(() => {
  //   if (authStore.user && location.pathname.includes('login')) {
  //     navigate('/');
  //   } else if (!authStore.user) {
  //     navigate('/login');
  //   }
  // }, [authStore.user]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route) => {
          return <Route path={route.path} element={<route.component />} key={route.path} />;
        })}
      </Routes>
    </Suspense>
  );
};

export default observer(RouteConfig);
