import { Button, Input, Typography } from '@mui/material';
import { useState } from 'react';
import { pushDataForThirdParty } from 'utils/pushDataForThirdParty';

export const TestExaminationData = () => {
  const [urlValue, setUrlValue] = useState<string>('');
  const handleCallApi = () => {
    if (urlValue) {
      let data = {
        result: {
          status: 'success',
          exam_type: 1,
          answer_id: 21474,
          url_id: 318,
          evaluation:
            '\u884c\u52d5\u6d3e\u30bf\u30a4\u30d7\uff08\uff25\uff23\uff2c\uff21\u578b\uff09',
          comment:
            '\u6d3e\u624b\u597d\u304d\u3067\u4ed6\u4eba\u306b\u7a4d\u6975\u7684\u306b\u8a71\u3057\u304b\u3051\u6d3b\u52d5\u7684\u3067\u3059\u3002\u7269\u4e8b\u306e\u5909\u5316\u304c\u82e6\u624b\u3067\u4f55\u4e8b\u306b\u3082\u7121\u9813\u7740\u306a\u3068\u3053\u308d\u304c\u3042\u308a\u307e\u3059\u3002\u307e\u305f\u3001\u5f37\u5f15\u3067\u81ea\u5df1\u4e2d\u5fc3\u7684\u306a\u884c\u52d5\u3092\u3068\u308b\u3053\u3068\u304c\u3042\u308a\u3001\u8efd\u7387\u306b\u898b\u3089\u308c\u304c\u3061\u3067\u3059\u306e\u3067\u3044\u308d\u3044\u308d\u306a\u30a2\u30a4\u30c7\u30a2\u3092\u99c6\u4f7f\u3057\u3001\u614e\u91cd\u306b\u53d6\u308a\u7d44\u307f\u6301\u3061\u5473\u3067\u3042\u308b\u7a4d\u6975\u6027\u3092\u767a\u63ee\u3067\u304d\u308c\u3070\u3055\u3089\u306b\u3088\u3044\u3067\u3057\u3087\u3046\u3002'
        }
      };
      pushDataForThirdParty(data, urlValue);
    }
  };
  return (
    <>
      <Typography component="div" sx={sxStyle.root}>
        <Typography component="h1" sx={sxStyle.root}>
          Test Data
        </Typography>
        <Typography component="div" sx={sxStyle.inputRegion}>
          <Input
            sx={sxStyle.input}
            type="text"
            value={urlValue}
            onChange={(e) => setUrlValue(e.target.value)}
          />
        </Typography>
        <Button
          sx={sxStyle.btnAction}
          variant="outlined"
          color="primary"
          onClick={() => handleCallApi()}
        >
          Submit
        </Button>
      </Typography>
    </>
  );
};

const sxStyle = {
  root: { width: '100%', margin: 'auto', maxWidth: '960px' },
  inputRegion: { display: 'flex', marginBottom: '20px', width: '100%' },
  input: { display: 'flex', marginBottom: '20px', marginTop: '20px', width: '100%' },
  labelText: { textAlign: 'left', margin: 'auto', width: '20%' },
  btnAction: {
    width: '236px',
    height: '70px',
    fontSize: '20px',
    color: '#fff',
    backgroundColor: '#088a98',
    '&:hover': {
      opacity: '.4',
      background: '#088a98',
      borderColor: '#088a98'
    }
  }
};

export default TestExaminationData;
