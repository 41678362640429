import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useStore } from 'stores';

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number; totalTime: number; division: number }
) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ minWidth: 230 }}>
        <Typography variant="body2" sx={sxStyle.timeCount} color="text.secondary">
          <Typography component="span" sx={sxStyle.textTimeCount}>
            {props.division == 1 && '残り時間'}
            {props.division != 1 && '残り時間の目処'}
          </Typography>
          {`${Math.round(props.totalTime)}`}
          <Typography component="span" sx={sxStyle.textTimeCount}>
            秒
          </Typography>
        </Typography>
      </Box>
      <Box sx={{ width: '100%', ml: 2 }}>
        <LinearProgress sx={sxStyle.linearProgress} variant="determinate" {...props} />
      </Box>
    </Box>
  );
};

const ProcessBarComponent = () => {
  const { examinationStore } = useStore();
  const [progress, setProgress] = React.useState(100);
  const [totalTimeExamination, setTotalTimeExamination] = React.useState(
    examinationStore.totalTime
  );

  React.useEffect(() => {
    let timer: any;
    if (examinationStore.totalTime > 0) {
      setProgress(100);
      setTotalTimeExamination(examinationStore.totalTime);
      timer = setInterval(() => {
        setTotalTimeExamination((prevTotalTime) => {
          return prevTotalTime - 1;
        });
        setProgress((prevProgress) => {
          if (prevProgress <= 1) {
            clearInterval(timer);
            if (examinationStore.divisionActive != 2 && examinationStore.divisionActive != 3) {
              examinationStore.setIsTimeOut(true);
              examinationStore.isStart = false;
            }

            setTotalTimeExamination(0);
            return 0;
          } else {
            return prevProgress - 100 / examinationStore.totalTime;
          }
        });
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    } else {
      clearInterval(timer);
    }
  }, [examinationStore.timeHasPassed, examinationStore.totalTime]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel
        value={progress}
        totalTime={totalTimeExamination}
        division={examinationStore.divisionActive}
      />
    </Box>
  );
};

const sxStyle = {
  linearProgress: () => ({
    height: 16,
    borderRadius: 0.5,
    backgroundColor: '#FDE7CC ',

    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FA8E05'
    }
  }),
  timeCount: { fontSize: '40px', color: '#f69c00', fontWeight: 'bold ', textAlign: 'left' },
  textTimeCount: { fontSize: '15px', color: '#f69c00', fontWeight: 'bold ' }
};

export default observer(ProcessBarComponent);
