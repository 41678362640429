import { action, observable, makeObservable } from 'mobx';
import { User } from 'models';

export class Auth {
  @observable user = localStorage.getItem('user') as any;

  constructor() {
    makeObservable(this);
  }

  @action
  setUser = (user: User) => {
    this.user = user;
  };

  @action
  clearUser = () => {
    this.user = null as any;
    localStorage.removeItem('user');
  };
}
