import { formModel } from './form-model';

const {
  formField: { firstName, lastName, furigana1, furigana2, age, department }
} = formModel;

export const initialValues = {
  [firstName.name]: '',
  [lastName.name]: '',
  [furigana1.name]: '',
  [furigana2.name]: '',
  [age.name]: '',
  [department.name]: ''
};
