import { configure } from 'mobx';
import React from 'react';
import RouteConfig from 'routes/RouteConfig';
import './App.css';

configure({ useProxies: 'never', enforceActions: 'never' });

function App() {
  return (
    <div className="App">
      <RouteConfig />
    </div>
  );
}

export default App;
