import { Typography } from '@mui/material';
import * as React from 'react';
import ProcessBarComponent from './components/ProcessBar';

const TimeProcessContainer = () => {
  return (
    <Typography component="div" sx={sxStyle.root}>
      <ProcessBarComponent />
    </Typography>
  );
};

const sxStyle = {
  root: { margin: 'auto 0', width: '100%', display: 'flex', padding: '5px' },
  logo: { margin: 'auto 0', width: '30' },
  content: { margin: 'auto 0', width: '70%' }
};

export default TimeProcessContainer;
