import * as yup from 'yup';
import { formModel } from './form-model';
const {
  formField: { firstName, lastName, furigana1, furigana2, age, department }
} = formModel;

export const validationSchema = [
  yup.object().shape({
    [firstName.name]: yup.string().required(firstName.requiredErrorMsg),
    [lastName.name]: yup.string().required(lastName.requiredErrorMsg),
    [furigana1.name]: yup.string().required(furigana1.requiredErrorMsg),
    [furigana2.name]: yup.string().required(furigana2.requiredErrorMsg),
    [age.name]: yup
      .number()
      .required(age.requiredErrorMsg)
      // @ts-ignore
      .test('age', age.lengthErrorMsg, (age) => age >= 0 && age <= 100),
    [department.name]: yup.string()
  })
];
