import { Typography } from '@mui/material';
import * as React from 'react';

interface HeaderQuestionProps {
  contentQuestion: string;
  // totalQuestion: number;
  questionNumber: string;
}

const HeaderQuestion = (props: HeaderQuestionProps) => {
  const { questionNumber } = props;
  return (
    <Typography component="div" sx={sxStyle.root}>
      <Typography component="div">
        <Typography component="span" sx={sxStyle.questionNumber}>
          {questionNumber}
        </Typography>
        {/* <Typography component="span" sx={sxStyle.totalQuestion}>
          /{totalQuestion}
        </Typography> */}
      </Typography>
      {/* <Typography sx={sxStyle.content} component="div">
        {contentQuestion}
      </Typography> */}
    </Typography>
  );
};

const sxStyle = {
  root: { margin: 'auto 0', width: '100%', display: 'flex', padding: '5px' },
  logo: { margin: 'auto 0', width: '30' },
  content: { margin: 'auto 20px', width: '80%', fontSize: '16px', textAlign: 'left' },
  questionNumber: { fontSize: '45px', color: '#088a98' },
  totalQuestion: { fontSize: '23px', color: '#088a98' }
};

export default HeaderQuestion;
