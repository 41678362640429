import { Button, Divider, Typography } from '@mui/material';
import { TimeOut } from 'components/Common';
import Header from 'components/Layout/Header/Header';
import HeaderQuestion from 'components/Layout/Header/HeaderQuestion';
import TimeProcessContainer from 'components/TimeProcess/TimeProcessContainer';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ExaminationService from 'services/examinationService';
import { useStore } from 'stores';
import ModalLoading from '../../../components/Loading/ModalLoading';
import AnswerOptions from '../component/AnswerOptions';
import { useParams } from 'react-router-dom';

const SingleQuestion = observer(() => {
  const { examinationStore, todoStore } = useStore();
  const [contentQuestion, setContentQuestion] = useState('');
  const [questionNumber, setQuestionNumber] = useState('Q1');
  // const [totalNumberQuestion, setTotalNumberQuestion] = useState(0);
  const [answerList, setAnswerList] = useState<any>(null);
  const [division, setDivision] = useState<number>(1);
  const [isHasData, setIsHasData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [numberQuestionNotChoose, setNumberQuestionNotChoose] = useState<string>('');
  const [imageQuestionUrl, setImageQuestionUrl] = useState<any>({});
  const [imageHeaderUrl, setHeaderImageUrl] = useState<string>('');
  const params = useParams();

  const isTabletDevice = useMediaQuery({
    // @ts-ignore
    query: '(max-width: 768px)'
  });

  const startTime = () => {
    if (examinationStore.examType == 1) {
      if (examinationStore.divisionActive == 1) {
        examinationStore.setTotalTime(1500);
        // examinationStore.setTotalTime(timeTest);
      } else if (examinationStore.divisionActive == 2 || examinationStore.divisionActive == 3) {
        examinationStore.setTotalTime(720);
        // examinationStore.setTotalTime(timeTest);
      }
    } else if (examinationStore.examType === 2) {
      if (examinationStore.divisionActive == 1) {
        examinationStore.setTotalTime(360);
        // examinationStore.setTotalTime(timeTest);
      } else if (examinationStore.divisionActive == 2) {
        examinationStore.setTotalTime(720);
        // examinationStore.setTotalTime(timeTest);
      }
    }
  };

  // const stopTime = () => {
  //   examinationStore.isStart = false;
  //   examinationStore.setTotalTime(0);
  // };

  const loadImageQuestion = async (index: number) => {
    const module = await import(
      `../images/` +
        (examinationStore.examType == 1
          ? examinationStore.questionNoActive < 10
            ? 'datf_00'
            : 'datf_0'
          : examinationStore.questionNoActive < 10
          ? 'datm_00'
          : 'datm_0') +
        (examinationStore.questionNoActive != 0 ? examinationStore.questionNoActive : 1) +
        `_0` +
        (index +
          (examinationStore.examType == 1 ||
          (examinationStore.examType == 2 && examinationStore.questionNoActive > 1)
            ? 1
            : 2)) +
        `.jpg`
    );
    return module.default;
  };

  const loadImageHeader = async () => {
    const module = await import(
      `../images/` +
        (examinationStore.examType == 1
          ? examinationStore.questionNoActive < 10
            ? 'datf_00'
            : 'datf_0'
          : examinationStore.questionNoActive < 10
          ? 'datm_00'
          : 'datm_0') +
        (examinationStore.questionNoActive > 0 ? examinationStore.questionNoActive : 1) +
        `_01.jpg`
    );
    return module.default;
  };

  useEffect(() => {
    if (answerList?.length > 0)
      answerList.map(async (answer, index) => {
        await loadImageQuestion(index).then((url) => {
          setImageQuestionUrl((prevState) => {
            return { ...prevState, [index]: url };
          });
        });
      });
  }, [answerList]);

  useEffect(() => {
    loadImageHeader().then((url) => setHeaderImageUrl(url));
  }, [examinationStore.examType, examinationStore.questionNoActive]);

  // call api and get list question and answers
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const basicInfoData = JSON.parse(localStorage.getItem('examinationData') ?? '');
      const parameters: any = {
        exam_type: examinationStore.examType,
        division: examinationStore.divisionActive,
        seed: `${basicInfoData?.values?.currentStartTime ?? ''}${params['*']}`
      };
      const response = await ExaminationService.getQuestionList(parameters);
      setIsLoading(false);
      if (response && response.questions?.length > 0) {
        setIsHasData(true);
        const questionsRes = response.questions.map((qu, quIdx) => ({
          ...qu,
          ...{ questionNumber: quIdx + 1 }
        }));
        examinationStore.setQuestionList(questionsRes);
        // setTotalNumberQuestion(response.questions?.length);
        let firstQuestion = questionsRes.find((question) => question.questionNumber === 1);
        setContentQuestion(firstQuestion?.description);
        setQuestionNumber('設問' + firstQuestion?.questionNumber);
        setAnswerList(firstQuestion?.m_question_details);
        examinationStore.setQuestionNoActive(firstQuestion.question_no);
        examinationStore.setQuestionNumberDisplay(1);
      } else {
        setIsHasData(false);
      }
    })();
  }, [examinationStore.examType]);

  // check and start timer countdown
  useEffect(() => {
    if (!examinationStore.isStart) {
      startTime();
    }
  }, []);

  const nextQuestionHandle = () => {
    window.scrollTo(0, 0);
    let questionNumberDisplay = examinationStore.questionNumberDisplay;
    if (questionNumberDisplay > 0) {
      let questionNext = examinationStore.questionList
        .slice()
        .find((question) => question.questionNumber === questionNumberDisplay + 1);
      setContentQuestion(questionNext?.description);
      setQuestionNumber('設問' + questionNext?.questionNumber);
      setAnswerList(questionNext.m_question_details);
      examinationStore.setQuestionNumberDisplay(questionNext?.questionNumber);
      examinationStore.setQuestionNoActive(questionNext?.question_no);
    }
    if (questionNumberDisplay + 1 == examinationStore.questionList?.slice().length) {
      validateChooseAnswer();
    }
  };

  const backQuestionHandle = () => {
    window.scrollTo(0, 0);
    let questionNumberDisplay = examinationStore.questionNumberDisplay;

    if (questionNumberDisplay > 0) {
      let questionBack = examinationStore.questionList
        .slice()
        .find((question) => question.questionNumber === questionNumberDisplay - 1);
      setContentQuestion(questionBack?.description);
      setQuestionNumber('設問' + questionBack?.questionNumber);
      setAnswerList(questionBack?.m_question_details);
      examinationStore.setQuestionNumberDisplay(questionBack?.questionNumber);
      examinationStore.setQuestionNoActive(questionBack?.question_no);
    }
  };

  const updateAnswerOfQuestion = async (data) => {
    const response = await ExaminationService.addInfoExaminationAndAnswerDivision1(data);
    if (response && response.result?.status === 'success') {
      return response.result;
    } else {
      return null;
    }
  };

  const updateAnswerDetailOfQuestion = async (data) => {
    const response = await ExaminationService.addAnswerDetail(data);
    if (response && response.result?.status === 'success') {
      return response.result;
    } else {
      return null;
    }
  };

  const nextDivisionHandle = async () => {
    setIsLoading(true);
    let examinationData = JSON.parse(localStorage.getItem('examinationData') || 'null');
    if (examinationData) {
      let response: any;
      if (examinationStore.issueType == 1) {
        let dataAnswer = {
          base_id: examinationStore.baseId,
          url_id: examinationStore.urlId,
          exam_type: examinationStore.examType,
          name: examinationData.values.firstName + examinationData.values.lastName,
          name_kana: examinationData.values.furigana1 + examinationData.values.furigana2,
          age: examinationData.values.age,
          department: examinationData.values.department,
          answers: examinationData.answers
        };
        response = await updateAnswerOfQuestion(dataAnswer);
      } else {
        let dataAnswerDetail = {
          answers: examinationData.answers,
          answer_id: examinationStore.answerId
        };
        response = await updateAnswerDetailOfQuestion(dataAnswerDetail);
        // pushDataForThirdParty(response, examinationStore.responseUrl);
      }
      setIsLoading(false);
      if (response || examinationStore.isTimeOut) {
        // setIsCompleteDivision(true);
        if (examinationStore.issueType == 1) {
          examinationStore.setAnswerId(response?.answer_id);
        }
        examinationData.answers = [];
        localStorage.setItem('examinationData', JSON.stringify(examinationData));
        examinationStore.setIsTimeOut(false);
        setDivision(2);
        todoStore.setPageName('introduces');
      }
    }
  };

  const handleNextDivisionTimout = async (division) => {
    if (division == 1) {
      await nextDivisionHandle();
    }
  };

  const handleSubmitDataTimeout = () => {};

  const validateChooseAnswer = () => {
    setNumberQuestionNotChoose('');
    let examinationData = JSON.parse(localStorage.getItem('examinationData') || 'null');
    if (examinationData) {
      if (examinationStore.questionList?.slice().length > 0) {
        let idAnswers: any[] = [];
        examinationStore.questionList?.slice().filter((question) => {
          let idAnswerDetails = question.m_question_details.map((detail) => ({
            questionNo: question.question_no,
            detailNo: detail.detail_no,
            division: detail.division
          }));
          idAnswers = [...idAnswerDetails, ...idAnswers];
        });

        if (examinationData.answers?.length > 0) {
          let isValid = idAnswers.every((answer) =>
            examinationData.answers.some(
              (answerChose) =>
                answerChose.questionNo == answer.questionNo &&
                answerChose.detailNo == answer.detailNo &&
                answerChose.division == answer.division
            )
          );
          if (isValid) {
            setIsValidForm(true);
            return;
          }
        }
        let arrNumberQuestionNotChoose: any[] = [];
        idAnswers.sort((a, b) => a.questionNo - b.questionNo);
        idAnswers.forEach((answer) => {
          if (
            !examinationData.answers?.some(
              (answerChose) =>
                answerChose.questionNo == answer.questionNo &&
                answerChose.detailNo == answer.detailNo &&
                answerChose.division == 1
            ) &&
            answer.questionNo != Math.max(...idAnswers.map((o) => o.questionNo))
          ) {
            arrNumberQuestionNotChoose.push('設問' + answer.questionNo + '-' + answer.detailNo);
          }
        });
        setNumberQuestionNotChoose(
          arrNumberQuestionNotChoose.length > 0
            ? arrNumberQuestionNotChoose.join('、') + '未回答です。'
            : ''
        );
        setIsValidForm(false);
        return;
      } else {
        setIsValidForm(false);
        return;
      }
    }
  };

  const radioChangeFunc = () => {
    validateChooseAnswer();
  };

  return (
    <>
      {division && division === 1 && isHasData && (
        <>
          <Header examType={examinationStore.examType} />
          {!examinationStore.isTimeOut && (
            <>
              <TimeProcessContainer />
              <HeaderQuestion
                contentQuestion={contentQuestion}
                // totalQuestion={totalNumberQuestion}
                questionNumber={questionNumber}
              />
              <Divider sx={sxStyle.dividerHome} />
              <Typography sx={sxStyle.content} component="div">
                {contentQuestion.split('\n').map((content, idx) => (
                  <Typography key={idx} component="p">
                    {content}
                  </Typography>
                ))}
              </Typography>
              {((examinationStore.questionNoActive == 1 && examinationStore.examType == 2) ||
                examinationStore.examType == 1) && (
                <Typography sx={sxStyle.rootImage} component="div">
                  <img
                    width="100%"
                    src={
                      imageHeaderUrl
                      // require(`../images/` +
                      //   (examinationStore.examType == 1
                      //     ? examinationStore.questionNoActive < 10
                      //       ? 'datf_00'
                      //       : 'datf_0'
                      //     : examinationStore.questionNoActive < 10
                      //     ? 'datm_00'
                      //     : 'datm_0') +
                      //   (examinationStore.questionNoActive > 0
                      //     ? examinationStore.questionNoActive
                      //     : 1) +
                      //   `_01.jpg`).default
                    }
                  />
                </Typography>
              )}
              <Typography component="div" sx={sxStyle.rootAnswer}>
                {answerList?.map((answer, index) => (
                  <Typography key={'answerRegion' + index} component="div">
                    {((index != 0 && examinationStore.examType == 1) ||
                      examinationStore.examType == 2) && (
                      <Typography key={'divimage' + index} component="div">
                        <img
                          width="100%"
                          key={'image' + index}
                          src={
                            imageQuestionUrl[index] ?? ''
                            // require(`../images/` +
                            //   (examinationStore.examType == 1
                            //     ? examinationStore.questionNoActive < 10
                            //       ? 'datf_00'
                            //       : 'datf_0'
                            //     : examinationStore.questionNoActive < 10
                            //     ? 'datm_00'
                            //     : 'datm_0') +
                            //   (examinationStore.questionNoActive != 0
                            //     ? examinationStore.questionNoActive
                            //     : 1) +
                            //   `_0` +
                            //   (index +
                            //     (examinationStore.examType == 1 ||
                            //     (examinationStore.examType == 2 &&
                            //       examinationStore.questionNoActive > 1)
                            //       ? 1
                            //       : 2)) +
                            //   `.jpg`).default
                          }
                        />
                      </Typography>
                    )}
                    <Typography
                      key={'div' + index}
                      component={'div'}
                      sx={isTabletDevice ? sxStyle.answerRegionMobile : sxStyle.answerRegion}
                    >
                      {/* <Typography
                        key={'span' + index}
                        sx={{ marginTop: '10px', mr: 2, fontWeight: 'bold' }}
                        component={'span'}
                      >
                        {index + 1 + ')'}
                      </Typography> */}
                      <AnswerOptions
                        key={index}
                        questionNo={questionNumber}
                        detailNo={answer?.detail_no}
                        division={1}
                        isNullChoice={
                          (examinationStore.examType == 1 &&
                            (examinationStore.questionNoActive == 13 ||
                              examinationStore.questionNoActive == 19)) ||
                          (examinationStore.examType == 2 && examinationStore.questionNoActive == 8)
                            ? true
                            : false
                        }
                        choice1={answer?.choice1}
                        choice2={answer?.choice2}
                        choice3={answer?.choice3}
                        choice4={answer?.choice4}
                        choice5={answer?.choice5}
                        radioChangeFunc={radioChangeFunc}
                      />
                    </Typography>
                  </Typography>
                ))}
              </Typography>
              <Typography sx={sxStyle.btnRegion} component="div">
                {questionNumber !== '設問1' && (
                  <Button
                    sx={{ ...sxStyle.btnAction, ...{ mr: 1 } }}
                    variant="outlined"
                    color="primary"
                    onClick={backQuestionHandle}
                  >
                    戻る
                  </Button>
                )}
                {questionNumber == '設問' + examinationStore.questionList?.slice()?.length &&
                  numberQuestionNotChoose && (
                    <Typography component="div" sx={{ color: 'red' }}>
                      {numberQuestionNotChoose}
                    </Typography>
                  )}
                {questionNumber !== '設問' + examinationStore.questionList?.slice()?.length && (
                  <Button
                    sx={{ ...sxStyle.btnAction, ...{ mr: 1 } }}
                    variant="outlined"
                    color="primary"
                    onClick={nextQuestionHandle}
                  >
                    次の問題へ
                  </Button>
                )}
                {isValidForm && (
                  <Button
                    sx={sxStyle.btnAction}
                    variant="outlined"
                    color="primary"
                    onClick={nextDivisionHandle}
                  >
                    第２部へ進む
                  </Button>
                )}
              </Typography>
            </>
          )}
          {examinationStore.isTimeOut && (
            <TimeOut
              handleNextDivisionTimout={handleNextDivisionTimout}
              handleSubmitData={handleSubmitDataTimeout}
            />
          )}
        </>
      )}
      {!isHasData && (
        <>
          <Typography component={'div'}>Not found data</Typography>
        </>
      )}
      {isLoading && <ModalLoading />}
    </>
  );
});
const sxStyle = {
  dividerHome: { backgroundColor: '#0ca5b0', width: '98%', height: '2px', margin: 'auto' },
  rootImage: { maxWidth: '800px', margin: 'auto' },
  rootAnswer: { maxWidth: '800px', margin: 'auto' },
  btnRegion: {
    marginBottom: '20px !important',
    bottom: '20px',
    width: '95%',
    margin: 'auto',
    marginTop: '20px',
    height: '150px'
  },
  btnAction: {
    width: '236px',
    height: '70px',
    fontSize: '20px',
    color: '#fff',
    backgroundColor: '#088a98',
    '&:hover': {
      opacity: '.4',
      background: '#088a98',
      borderColor: '#088a98'
    }
  },
  answerRegion: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '600px',
    justifyContent: 'center',
    margin: 'auto'
  },
  content: {
    margin: 'auto',
    width: '100%',
    maxWidth: '800px',
    fontSize: '16px',
    textAlign: 'left'
  },
  answerRegionMobile: {
    display: 'flex'
  }
};

export default SingleQuestion;
