// store.ts
import { createContext, useContext } from 'react';
import { Auth } from './AuthStore';
import { Examination } from './ExaminationStore';
import { Todo } from './TodoStore';

export const rootStore = {
  todoStore: new Todo(),
  authStore: new Auth(),
  examinationStore: new Examination()
};

export type TRootStore = typeof rootStore;
const RootStoreContext = createContext<null | TRootStore>(null);

// Create provider for provide state all app
// use in CompleteExamination.tsx
export const Provider = RootStoreContext.Provider;

/** return store, just use in function componenr */
export function useStore() {
  /** store will contain all data */
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
