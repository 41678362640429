import { Typography } from '@mui/material';
import React from 'react';

export const ErrorPage = (props: any) => {
  const { message = '' } = props;
  return (
    <Typography component="div" sx={sxStyle.notiRegion}>
      <Typography component="span" sx={sxStyle.notiDescription}>
        {message}
      </Typography>
    </Typography>
  );
};

const sxStyle = {
  notiRegion: {
    width: '100%',
    marginTop: '50px'
  },
  notiDescription: {
    fontSize: '16px'
  }
};
