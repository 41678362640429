import { Button, Typography } from '@mui/material';
import React from 'react';
import { useStore } from 'stores';

const DatFullScreenPage = (props: any) => {
  const { todoStore } = useStore();
  // eslint-disable-next-line no-unused-vars
  const { bmname, basename } = props;

  const handleClickNext = () => {
    todoStore.setPageName('examination');
  };
  return (
    <>
      <Typography component="div" sx={sxStyle.root}>
        {/* <Typography component="div" sx={sxStyle.regionOne}>
          <Typography component="p">＜受検前の確認＞</Typography>
          <Typography sx={sxStyle.textInfo} component="p">
            受検内容：DAT-FULL
          </Typography>
          <Typography sx={sxStyle.textInfo} component="p">
            拠点統括名：{bmname}
          </Typography>
          <Typography sx={sxStyle.textInfo} component="p">
            拠点名：{basename}
          </Typography>
        </Typography> */}
        <Typography component="div">
          <Typography component="p">＜受検内容＞</Typography>
          <Typography sx={sxStyle.textInfo} component="p">
            第１部：１００問（制限時間２５分）
          </Typography>
          <Typography sx={sxStyle.textInfo} component="p">
            第２部：１００問（１２分を目処）
          </Typography>
          <Typography sx={sxStyle.textInfo} component="p">
            第３部：１００問（１２分を目処）
          </Typography>
        </Typography>
        <Typography component="div" sx={sxStyle.buttonRegion}>
          <Button
            sx={sxStyle.btnAction}
            color="primary"
            variant="outlined"
            onClick={handleClickNext}
          >
            受検に進む
          </Button>
        </Typography>
      </Typography>
    </>
  );
};

const sxStyle = {
  root: { textAlign: 'left', margin: 'auto', width: '80%' },
  btnAction: {
    width: '236px',
    height: '70px',
    fontSize: '20px',
    color: '#fff',
    margin: 'auto',
    backgroundColor: '#088a98',
    '&:hover': {
      opacity: '.4',
      background: '#088a98',
      borderColor: '#088a98'
    }
  },
  textInfo: { marginLeft: '15px' },
  regionOne: { marginBottom: '30px' },
  buttonRegion: { marginTop: '50px', display: 'flex' }
};

export default DatFullScreenPage;
